<template>
<div>
    <img src="@/assets/FirstContent.jpg" alt="Banner">
  <div class="container row row-cols-auto m-auto">
    <Buttons v-for="AffiliateLink in AffiliateLinks" :key="AffiliateLink.ID" :title="AffiliateLink.title" :url="AffiliateLink.url"></Buttons>
  </div>
</div>
</template>

<script>
import Buttons from "./Buttons";
import {mapState} from "vuex";

export default {
  name: "Banner",
  components:{
    Buttons
  },
  computed:{
    ...mapState({
      AffiliateLinks: state => state.affiliateWebsites
    })
  }
}
</script>

<style scoped>
div{
  height: 400px;
}
img{
  width: 100%;
  height: 400px;
  position: absolute;
  object-fit: cover;
  z-index: -1;
}
</style>