<template>
  <div class="bg-primary mrg">
    <div class="row container m-auto">
      <div class="col-sm-2"><font-awesome-icon @click="redirect('https://www.facebook.com/FG-Immobiliare-1773445906248484\'')" class="m-auto my-lg-5 d-flex flex-row" size="3x" :icon="['fab', 'facebook']"></font-awesome-icon></div>
      <div class="col-lg-4 text-center m-auto"><h4 class="mt-3">FG Immobiliare</h4>
        <p><a href
              @click="redirect('https://www.google.com/maps/place/Via+Antonio+Gramsci,+97B,+95030+Gravina+di+Catania+CT/@37.562533,15.067044,16z/data=!4m5!3m4!1s0x1313fda57d912df1:0x9089221202e8ed41!8m2!3d37.5625334!4d15.0670435?ll=37.562533,15.067044&z=16&t=m&hl=it&gl=IT&mapclient=embed&q=Via+Antonio+Gramsci,+97B+95030+Gravina+di+Catania+CT&shorturl=1')">Via
          Antonio Gramsci, 97/B, 95030 Gravina di Catania CT
        </a><br> A.M: 9:00 - 13:00<br>
          P.M: 16:00 - 20:00</p></div>
      <div class="col-sm-3 mx-xl-auto my-0 d-flex justify-content-center align-self-center">
        <Contacts></Contacts>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import Contacts from "./Contacts";

export default {
  name: "Footer",
  methods: {
    ...mapMutations({
      redirect: 'redirectToAffiliate'
    })
  },
  components: {
    Contacts
  }
}
</script>

<style scoped>
div {
  color: white;
}

.mrg{
  padding: 60px;
}
.row div{
  margin: 20px auto;

}

a {
  color: white;
}
</style>