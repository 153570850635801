<template>
  <div class="col-auto d-flex flex-row h-auto m-auto">
<button class="btn btn-primary px-5 p-3" @click="redirect(url)">{{title}}</button>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: "Buttons",
  props:{
    title:{
      type:String,
      required:true
    },
    url:{
      type:String,
      required:true
    }
  },
  methods:{
    ...mapMutations({
      redirect: 'redirectToAffiliate'
    })
  }
}
</script>

<style scoped>

Button{
border-radius: 50px;font-size: 20px;
}

</style>