<template>
  <div class="container m-auto">
    <div class="row-cols-3 d-flex flex-row">
      <div>
        <picture class="row">
          <source srcset="@/assets/collage_1.webp" type="image/webp">
          <img src="@/assets/collage_1.jpg" alt="House 1">
        </picture>
      </div>
      <div>
        <picture class="row">
          <source srcset="@/assets/collage_2.webp" type="image/webp">
          <img src="@/assets/collage_2.jpg" alt="House 2">
        </picture>
      </div>
      <div>
        <picture class="row">
          <source srcset="@/assets/collage_3.webp" type="image/webp">
          <img src="@/assets/collage_3.jpg" alt="House 3">
        </picture>
      </div>
    </div>
    <div class="row-cols-3 d-flex flex-row">
      <div>
        <picture class="row">
          <source srcset="@/assets/collage_4.webp" type="image/webp">
          <img src="@/assets/collage_4.jpg" alt="House 4">
        </picture>
      </div>
      <div>
        <picture class="row">
          <source srcset="@/assets/collage_5.webp" type="image/webp">
          <img src="@/assets/collage_5.jpg" alt="House 5">
        </picture>
      </div>
      <div>
        <picture class="row">
          <source srcset="@/assets/collage_6.webp" type="image/webp">
          <img src="@/assets/collage_6.jpg" alt="House 6">
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ImageGroup",
  data() {
    return {
      fileFormat: ['.webp', '.jpf', '.jpg']
    }
  },
  computed: {
    ...mapState({
      images: 'images'
    })
  },
  methods: {
    returnSrc(image, iteration) {
      return '@/assets/' + image + this.fileFormat[iteration]
    }
  }
}
</script>

<style scoped>
div {
  margin: 0;
}
</style>