import {createStore} from 'vuex'

export default createStore({
    state: {
        affiliateWebsites: [{
            url: 'https://www.case24.it/catania/gravina_di_catania/agenzie_immobiliari/f_g__immobiliare_servizi_bancari_tel_095_7254333_44099.html',
            title: 'Case24 FG  '
        },
            {
                url: 'https://www.idealista.it/pro/fg-pragma-ego-servizi-immobiliari-gravina-di-catania/',
                title: 'Idealista FG'
            },
            {
                url: 'https://agenzieimmobiliari.trovacasa.net/CT/Gravina_di_catania/annunci-TC-26067-F_g_immobiliare.html',
                title: 'TrovaCasa FG'
            },
            {
                url: 'https://www.immobiliare.it/agenzie-immobiliari/133777/fg-pragma-ego-servizi-gravina-di-catania/',
                title: 'Immobiliare FG'
            }],
        contacts: {
            telephone: '347-8211286',
            fax: '095-7254333',
            email: 'fgimmobiliare@gmail.com'
        },
        content: [{
            title: 'Chi Siamo',
            description: 'La FG Immobiliare è un\'agenzia che si pone come obiettivo, l\'essere il punto di riferimento per la ricerca e l\'offerta immobiliare sul territorio Nazionale. Nelle pagine del sito aggiornate quotidianamente,e possibile trovare le migliori proposte per le vostre necessita abitative, arrichite di descrizioni e foto.\n'
        },
            {
                title: 'Servizi',
                description: 'Intermediazione Finanziaria\n' +
                    'Mutui\n' +
                    'Consulenza'
            },
            {
                title: 'Banche Partner',
                description: 'Credem\n' +
                    'Unicredit\n' +
                    'Credito Siciliano\n'
            },
            {
                title: 'Dove puoi trovarci',
                description: 'Potete trovarci in Via Antonio Gramsci, 97/B, 95030 Gravina di Catania CT dal lunedì al venerdì.\n' +
                    '\n' +
                    'A.M: 9:00 - 13:00\n' +
                    'P.M: 16:00 - 20:00\n'
            }],
        images: ['collage_1', 'collage_2', 'collage_3', 'collage_4', 'collage_5', 'collage_6']
    },
    mutations: {
        redirectToAffiliate(state, url) {
            window.open(url)
        }
    },
    actions: {},
    getters: {}
})
