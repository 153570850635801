<template>
<div :class="{'divMargin': HighSpace, 'alignRight': right}" class="container-sm my-5">
  <h1 v-if="title">{{title}}</h1>
  <p v-for="line in description.split('\n')" :key="line.id">{{line}}</p>
</div>
</template>

<script>
export default {
  name: "Content",
  props:{
    title:{
      type:String,
      require: false
    },
    description:{
      type:String,
      require: true
    },
    HighSpace:{
      type:Boolean,
      default:false
    },
    right:{
      type:Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.divMargin{
  margin:  200px auto !important;
}
.alignRight{
  text-align: right;
}
h1{
  font-size: 80px;
}
p{
  font-size: 28px;
}
</style>