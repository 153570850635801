import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faPhone, faFax, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

import  'bootstrap/dist/css/bootstrap.css'

library.add(faPhone, faFax, faEnvelope)
library.add(faFacebook)

createApp(App).use(store).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
