<template>
  <div class="bg-white">
<div class="h-auto m-auto container row">
  <div class="col-auto m-auto">
    <img class="m-3" src="@/assets/logo.gif" alt="Logo"></div>
  <div class="col-auto m-auto"><contacts :email="true"></contacts></div>
  <div class="col-auto m-auto"><Email></Email></div>
</div></div>
</template>

<script>
import Contacts from "./Contacts";
import Email from "./Email";

export default {
  name: "Header",
  components:{
    Contacts,
    Email
  }
}
</script>

<style scoped>

</style>