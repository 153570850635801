<template>
  <div class="d-flex col-auto">
    <div class="col-auto m-3 text-end">
      <p class="my-2">
        <font-awesome-icon class="mx-3" icon="phone"></font-awesome-icon>
        {{ telephone }}
      </p>
      <p class="my-2">
        <font-awesome-icon class="mx-3" icon="fax"></font-awesome-icon>
        {{ fax }}
      </p>
    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "Contacts",
  computed: {
    ...mapState({
      telephone: state => state.contacts.telephone,
      fax: state => state.contacts.fax,
    })
  }
}
</script>

<style scoped>

</style>