<template>
  <div class="my-3 text-center">
    <p class="my-4">
      <font-awesome-icon class="mx-3" icon="envelope"></font-awesome-icon>{{ email }}
    </p>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Email",
  computed:{
    ...mapState({
      email: state=> state.contacts.email
    })
  }
}
</script>

<style scoped>
p {
  font-size: 16px;
}
</style>