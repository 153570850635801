<template>
  <div>
    <Header/>
    <Banner/>
    <Content :HighSpace="true" :description="Content[0].description" :title="Content[0].title"></Content>
    <ImageGroup></ImageGroup>
    <div class="container">
      <iframe title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.6827497234626!2d15.064854815588335!3d37.56253763211438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1313fda57d912df1%3A0x9089221202e8ed41!2sVia%20Antonio%20Gramsci%2C%2097B%2C%2095030%20Gravina%20di%20Catania%20CT!5e0!3m2!1sit!2sit!4v1601461781410!5m2!1sit!2sit"
              width="100%" height="500px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
              tabindex="0"></iframe>
    </div>

    <Content :description="Content[1].description" :title="Content[1].title"></Content>
    <Content :description="Content[2].description" :right="true" :title="Content[2].title"></Content>
    <Content :description="Content[3].description" :title="Content[3].title"></Content>

    <p class="container text-center">Per informazioni o per un appuntamento contattare la FGimmobiliare al telefono/fax 095-7254333 cell. 347-8211286.</p>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./components/Header";
import Banner from "./components/Banner";
import Content from "./components/Content";
import ImageGroup from "./components/ImageGroup";
import Footer from "./components/Footer";

import {mapState} from "vuex";

export default {
  name: 'App',
  components: {
    Content,
    Header, Banner, ImageGroup, Footer
  },
  computed: {
    ...mapState({
      Content: 'content'
    })
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');

div {
  font-family: "Roboto";
  font-weight: 300;
}
</style>
